import { css } from "@emotion/react";
import FC_BARCELONA from "assets/fonts/Barca/FCBARCELONA-Regular-web.woff2";
import FC_BARCELONA_BOLD from "assets/fonts/Barca/FCBARCELONA-Bold-web.woff2";
import honeycombBackground from "assets/clients/Barca/Backgrounds/Honeycomb.png";
import loadingBackground from "assets/clients/Barca/Backgrounds/Loading.png";

const barcaStyles = css`
  @font-face {
    font-family: "FC Barcelona";
    src: local("FC Barcelona"), url(${FC_BARCELONA}) format("woff2");
  }

  @font-face {
    font-family: "FC Barcelona";
    src: local("FC Barcelona"), url(${FC_BARCELONA_BOLD}) format("woff2");
    font-weight: bold;
  }

  &.barca-games {
    body,
    .custom-page {
      background-attachment: fixed;
      background-image: url(${honeycombBackground});
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      background-size: cover;
      &.padd-sty {
        padding-top: 0;
      }
    }

    .navbar-home,
    .partner-status-bar,
    .hide-footer {
      display: none !important;
    }

    .not-logo {
      padding-top: 1.25rem;
    }

    .title-md {
      font-size: 1rem;
    }

    .top-tournament-full-width {
      padding-top: 1.25rem;

      .top-tournaments-swiper {
        padding-left: 1.5rem;

        .top-tournament-card {
          .card {
            border-radius: 0.53rem;
          }

          .bottom-bar-container {
            border-radius: 0;
            bottom: 1rem;

            .title-sm,
            .ranking-button {
              color: #ffffff;
            }

            .rank-button,
            .play-button {
              border-radius: 0.375rem;
              width: 3.75rem;
            }

            .rank-button {
              background-color: #8aa0c1;
              border: 1px solid #92c4e9;

              &:hover {
                background-color: #9bc6e9;
              }

              &:active {
                background-color: #154284;
              }
            }

            .play-button {
              svg {
                height: 1.375rem !important;
                width: 1.375rem !important;
              }

              &:hover {
                background-color: #fd343d;
              }

              &:active {
                background-color: #a3061c;
              }
            }
          }

          .clock-participant-container {
            .clock-participant-bg-svg {
              display: none !important;
            }

            .clock-participant-text {
              bottom: 3.125rem;

              .me-4 {
                margin-right: 0 !important;
              }

              .clock-style {
                display: none !important;
              }
            }
          }
        }
      }
    }

    .modal_header,
    .leaderboard-wrapper {
      background-color: #154284;
    }

    .modal_header {
      box-shadow: 0 0.125rem 0.5rem 0 rgba(0, 0, 0, 0.5);
    }

    .gamename-style {
      color: #ffffff !important;
    }

    .gamename-style {
      font-family: "FC Barcelona", sans-serif;
    }

    .prizes-slider-container {
      .prizes-slider-item {
        color: #ffffff;

        .title-xs {
          font-size: 0.75rem;
          font-weight: bold;
        }

        .icon {
          background: #cf122d;

          svg {
            fill: #ffffff;
          }
        }
      }
    }

    .background {
      background-image: url(${honeycombBackground});
      background-size: contain;
    }

    .half-style {
      background-image: url(${honeycombBackground});
      background-repeat: no-repeat;
      background-size: cover;
      margin-bottom: 0 !important;

      .latest-score-container {
        span {
          color: #ffffff;
        }

        .latest-score-label {
          font-weight: bold;
        }
      }

      .player-rank-board {
        .first-board,
        .last-board {
          display: flex;
          flex-direction: column-reverse;
        }

        .label-style {
          background-color: #a3061c;
        }

        .name-style,
        .score {
          color: #ffffff;

          @media (width < 25.625rem) {
            font-size: 0.75rem;
          }
        }
      }
    }

    .second-half {
      .replay-button {
        display: none;
      }

      .button-bar {
        margin-bottom: 0;

        button {
          .btn-container {
            background: #8aa0c1;
            border: 1px solid #92c4e9;
            border-radius: 0.375rem;
            box-shadow: none;
            margin-bottom: 0;

            svg {
              color: #ffffff;
              width: 80%;
            }
          }

          &:hover {
            .btn-container {
              background: #9bc6e9;
            }
          }

          &:active {
            .btn-container {
              background: #154284;
            }
          }
        }
      }
    }

    .mission-page-container {
      padding-top: 1.25rem;

      .progressbar_wrapper {
        .circle_content {
          .helper_text {
            color: #ffffff;
          }
        }
      }

      .progress_bottom_curve {
        .clock_icon {
          fill: #ffffff;
        }
      }
    }

    .game-loading-overlay {
      background-color: transparent;
      background-image: url(${loadingBackground});
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 103vw;

      .progress-bar-container {
        .progress-bar {
          opacity: 1;
        }
      }
    }

    .browse-games {
      margin: 0 1.5rem;

      .title-md {
        margin: 0 !important;
      }

      .browse-games-container {
        gap: 1.25rem;

        .text-center {
          display: none;
        }

        .col-6 {
          flex: 1 1 45%;
          padding-bottom: 0 !important;
          width: auto;
        }
      }
    }

    .browse-game-card {
      margin-bottom: 0 !important;

      .game-info {
        background-color: #378dd4;
        border-radius: 0.53rem;
        height: fit-content;
        width: 100%;

        .image-style {
          border-radius: 0.53rem 0.53rem 0 0;
        }

        .info-container {
          .game-name {
            height: 1.781rem;
          }

          .triangle-div {
            top: 0.688rem;
            right: 0.5rem;

            @media (width < 25.625rem) {
              bottom: 0.5rem;
              right: 0rem;
              top: auto;
            }

            .triangle-button {
              border-radius: 0.375rem;
              height: 2.0625rem;
              padding: 0 !important;
              width: 4.125rem;

              @media (width < 25.625rem) {
                height: 1.5625rem;
                width: 3.125rem;
              }

              .icon-play {
                width: 1.375rem !important;
                height: 1.375rem !important;
              }
            }
          }
        }

        .triangle-bg {
          display: none;
        }
      }
    }

    .recommended-games {
      border-radius: 0;
      padding-top: 1.875rem !important;

      .header-style {
        padding-left: 1rem !important;
      }

      .games-container {
        gap: 1.25rem;
        justify-content: space-around;

        .browse-game-card {
          flex: 1 1 45%;
          max-width: 45%;

          .triangle-div {
            top: 0.5rem;
          }
        }
      }
    }

    .rank-list-container-first,
    .rank-list-container {
      .list-group-item {
        .text-style-transaction {
          color: #ffffff;
          font-size: 0.75rem;
          font-weight: bold;
        }
      }
    }

    .desktop-play-button-backward {
      background-color: #021d46;
      bottom: 10%;
      padding: 1rem 1rem 2.5rem 1rem;

      .motion-button {
        width: 100% !important;

        .desktop-play-footer {
          background: #cf122d !important;
          border-radius: 0.53rem;
          color: #ffffff !important;
          font-family: "FC Barcelona", sans-serif;
          font-size: 0.875rem;
          font-weight: bold;
          text-transform: uppercase;

          &:hover {
            background: #fd343d !important;
          }

          &:active {
            background: #a3061c !important;
          }
        }
      }
    }

    .leaderboard-container {
      color: #ffffff;

      .small-card {
        border-radius: 0.53rem;

        .button-alignment {
          .rank-btn,
          .play-btn-browse {
            border-radius: 0.375rem;
            color: #ffffff;

            svg {
              width: 1.375rem !important;
            }
          }

          .rank-btn {
            background-color: #8aa0c1;
            border: 1px solid #92c4e9;

            &:hover {
              background-color: #9bc6e9;
            }

            &:active {
              background-color: #154284;
            }
          }

          .play-btn-browse {
            background-color: #cf122d;

            &:hover {
              background-color: #fd343d;
            }

            &:active {
              background-color: #a3061c;
            }
          }
        }
      }
    }

    [data-rsbs-overlay] {
      background: transparent;
    }

    [data-rsbs-scroll] {
      border-top-left-radius: 0.53rem !important;
      border-top-right-radius: 0.53rem !important;
    }

    .close_wrapper {
      .close {
        fill: #ffffff;
      }
    }

    .loading-style {
      display: none;
    }
  }
`;

export default barcaStyles;
