import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const defaultResources = {
  en: {
    translation: {}
  }
};

i18n.use(initReactI18next).init({
  resources: defaultResources,
  fallbackLng: "en",
  keySeparator: false,
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: false,
    transKeepBasicHtmlNodesFor: ["b", "br", "i", "strong", "em"]
  }
});

export default i18n;
